const initialState = {
    list: [],
    detail: null,
    add: {
      accaunt: {},
      city: {},
      filial: []
    },
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    filters: {
      city: null,
      point: null,
      createdStartDate: null,
      createdEndDate: null,
      username: null,
      name: null,
    },
    editUser: {},
    count: 0
};

export default function users(state = initialState, action) {
  switch(action.type) {
    case 'SET_USERS': {
          return {
              ...state,
              list: action.users
          };
    }
    case 'SET_USERS_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
    }
    case 'SET_USERS_FILTERS': {
      return {
          ...state,
          filters: action.filters
      };
    }
    case 'SET_USERS_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    case 'SET_USER': {
      return {
          ...state,
          detail: action.user
      };
    }
    case 'SET_ADD': {
      return {
          ...state,
          add: action.data
      };
    }    
    default: return state;
  }
}