import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveModalAction, updateGood } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore } from '../../selectors';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Select from 'react-select';
import { fetchCities } from '../../action/handbook';
import { isEmpty } from 'lodash';

const SelectCity = ({ selected, onChange }) => {
  const dispatch = useDispatch();
  const loadingCities = useSelector(fromStore.loaderCitiesSelector);
  const cities = useSelector(fromStore.citiesListSelector);

  const onChangeSelect = (values) => {
    onChange(values ? values.map((item) => String(item.id)).join(',') : '');
  };

  const categoryCitiesIds = selected ? selected.split(',') : [];
  const selectedCities = cities.filter((item) => categoryCitiesIds.includes(String(item.id)));

  useEffect(() => {
    if (isEmpty(cities)) {
      dispatch(fetchCities(true, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Недоступно в городах</label>
        <div className="col-lg-9 col-xl-9">
          <Select
              isLoading={loadingCities}
              isDisabled={loadingCities}
              value={selectedCities}
              options={cities}
              getOptionLabel={(item) => `${item.name}`}
              getOptionValue={(item) => item.id}
              onChange={onChangeSelect}
              isMulti
              isClearable={false}
              placeholder="Выберите город(а)..."
              noOptionsMessage={() => 'Нет городов'}
          />
        </div>
    </div>
  );
}

function StopList() {
  const ref = useRef();
  const dispatch = useDispatch();
  const loadingUpdate = useSelector(fromStore.loaderUpdateGoodSelector);
  const good = useSelector(fromStore.detailGoodSelector);

  const initData = good ? {
    disabledCities: good.disabledCities,
  } : {
    disabledCities: '',
  };

  const [data, setData] = useState(initData);

  const isValid = () => {
    return true;
  }

  const onClose = () => !loadingUpdate && dispatch(setActiveModalAction({ field: 'stopList', value: false }));
  useOnClickOutside(ref, onClose);

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(updateGood(good.id, data));
    if (result) {
      onClose();
    }
  };

  const onChangeSelect = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  }

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Редактирование доступности</h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body" style={{ minHeight: '370px' }}>
                <SelectCity selected={data.disabledCities} onChange={onChangeSelect('disabledCities')} />
                <ErrorContainer field="goods" hasCloseButton={true}/>
            </div>

            <div className="modal-footer">
              <button disabled={loadingUpdate} type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loadingUpdate}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loadingUpdate })}>
                  Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default StopList;